import { computed, type UnwrapRef } from 'vue';
import { toValue, type MaybeRefOrGetter } from '@vueuse/core';

import { useConstantStore, useLanguageStore } from '@/helpers/composables/useStores';

import { getAllRawCatalogue, getRawCatalogue, type CatalogueItem } from '@/helpers/promotion-helper';

import type { TitleDetail } from '@/interfaces/title-details-graphql';
import type { TitleOfferFragment } from '@/components/buybox/graphql/fragments/Offer.fragment';

interface UsePromotionOptions {
	offers: MaybeRefOrGetter<TitleOfferFragment[]>;
	title: MaybeRefOrGetter<TitleDetail>;
	hardcodedFallbackOfferId?: MaybeRefOrGetter<number | undefined>;
}

export type UsePromotion = UnwrapRef<ReturnType<typeof usePromotion>>;

/** Only until we get the remaining translations. */
export const allowedPromotionLanguages = [
	'es',
	'en',
	'de',
	'fr',
	'pt',
	'it',
	'sv',
	'pl',
	'bg',
	'cs',
	'fi',
	'hu',
	'ko',
	'tr',
	'az',
	'el',
	'ja',
	'ro',
	'ru',
	'sr',
	'uk',
	'ur',
	'ar',
];

export function usePromotion({ offers, title, hardcodedFallbackOfferId }: UsePromotionOptions) {
	const { country } = useLanguageStore();
	const { allProvidersById } = useConstantStore();

	const promotionCatalogue = computed(() => {
		return new Map<number, CatalogueItem>(
			getAllRawCatalogue({ country, title })!
				.filter(item => !!allProvidersById.value[item.packageId])
				.map(item => [item.packageId, item])
		);
	});

	/** The highest priority title offer from the promotion catalogue based on the catalogue's priorities. */
	const bestTitleOffer = computed(() => {
		const availableOffers = toValue(offers);

		//FT_SWIPER_EXP
		const hardcodedFallbackOfferIdVal = toValue(hardcodedFallbackOfferId);

		if (hardcodedFallbackOfferIdVal) {
			const regularCatalogue = getRawCatalogue({ country, title }).filter(
				item => !!allProvidersById.value[item.packageId]
			);
			const bestOffer = regularCatalogue
				.map(item => availableOffers.find(offer => offer.package.packageId === item.packageId))
				.find(offer => offer?.package.packageId === hardcodedFallbackOfferIdVal);

			return bestOffer ?? null;
		}
		//FT_SWIPER_EXP

		const bestOffer = Array.from(promotionCatalogue.value.keys())
			.map(id => availableOffers.find(offer => offer.package.packageId === id))
			.find(offer => offer != null);

		return bestOffer ?? null;
	});

	/** If the title has an offer from a provider in the promotion catalogue. */
	const inPromotionCatalogue = computed(() => bestTitleOffer.value !== null);

	/**
	 * The promotion that matches the highest priority offer from the catalogue.
	 * If no match is found, returns the fallback promotion; currently Apple TV+.
	 */
	const catalogueItem = computed(() => {
		//FT_SWIPER_EXP
		const hardcodedFallbackOfferIdVal = toValue(hardcodedFallbackOfferId);

		if (hardcodedFallbackOfferIdVal) {
			const regularCatalogue = getRawCatalogue({ country, title }).filter(
				item => !!allProvidersById.value[item.packageId]
			);
			const hardcodedFallbackOffer = regularCatalogue.find(
				item => item.packageId === hardcodedFallbackOfferIdVal
			);
			if (hardcodedFallbackOffer) {
				return hardcodedFallbackOffer;
			}
		}
		//FT_SWIPER_EXP

		if (bestTitleOffer.value) {
			return promotionCatalogue.value.get(bestTitleOffer.value.package.packageId) ?? null;
		}

		const { done, value } = promotionCatalogue.value.values().next();

		if (!done) return value;

		return null;
	});

	/** The top offer from the promotion catalogue. */
	const promotionOffer = computed(() => {
		if (catalogueItem.value) {
			return catalogueItem.value.offer(bestTitleOffer, country, inPromotionCatalogue);
		}

		return bestTitleOffer.value;
	});

	return {
		/** All the promotions that should be included, sorted by priority. */
		promotionCatalogue,

		/** If the title has an offer from a provider in the promotion catalogue. */
		inPromotionCatalogue,

		/** The highest priority title offer from the promotion catalogue based on the catalogue's priorities. */
		bestTitleOffer,

		/**
		 * The promotion that matches the highest priority offer from the catalogue.
		 * If no match is found, returns the fallback promotion; currently Apple TV+.
		 */
		catalogueItem,

		/** The top offer from the promotion catalogue. */
		promotionOffer,
	};
}
